const marathi = [
    {
        // home_page=========================
        Home_slider1:"चला एकत्र येऊन एक इतिहास रचुया!",
        Home_slider2:"फक्त स्पर्धा नाही, यशाची शाश्वती!",
        Home_slider3:"तुमचे यश तुमची वाट पाहत आहे.",
        Home_secind:"ध्येय",
        Home_subheading:"आपल्याला आघाडीचा राजकीय ब्रँड बनवणे. ",
        Home_subdescription:"सध्याच्या युगात सर्वात महत्त्वाचा आहे तो ‘ब्रँड’. कारण लोकांचा विश्वास असतो तो ब्रँडवरच. त्यामुळे आमच्या सहकार्यांना देशातील आघाडीचे राजकीय ‘ब्रँड बनवणे हेच साई डिजिटेकचे सर्वात मोठे उद्दिष्ट आहे. निवडणूक व्यवस्थापन, जनसंपर्क, सर्वेक्षण, विश्लेषण आणि प्रचार मोहिमांसाठी आमच्या तज्ञ टीमच्या माध्यमातून आम्ही आमच्या सहकार्यांच्या यशासाठी कार्य करतो. फक्त नियोजनच नाही त्यांना एक यशस्वी ब्रँड बनवून त्यांच्या राजकीय यशाचे साथीदार बनणे हेच आमचे ध्येय आहे.",

        Home_titles:"उद्दिष्ट",
        Home_heading:" राजकीय ज्ञान आणि तंत्रज्ञानाचा आपल्या सहकार्यांच्या विजयासाठी प्रभावी वापर",
        Home_para:"आम्ही प्रत्येक ग्राहकाला चांगली सेवा प्रदान करण्यासाठी आमच्या तंत्रज्ञान आणि राजकारणाबद्दलच्या सखोल माहितीच्या आधारे सेवा देण्यासाठी वचनबद्ध आहोत. आमचे ध्येय म्हणजे प्रत्येक ग्राहकासाठी खास रणनीती तयार करणे, ज्याद्वारे दरवेळी प्रभावी विजय मिळवता येईल आणि ग्राहकाच्या अपेक्षा पूर्ण होतील.",

        Home_card_sectiontitle:"आमच्या सेवा",
        Home_card_sectionheading:"तुमच्या विजयाची साधने        ",
        Home_card_sectionpara:"जनतेशी आपला संपर्क आणि संबंध वाढवून एक प्रभावी राजकीय प्रचार मोहिमा राबविण्यासाठी वेगवेगळ्या प्रकारची राजकीय साधने तसेच सोशल मिडियाचा वापर केला जातो.        ",

        Home_card1:"डेटा सहाय्य",
        Home_card1para:"योग्य माहिती (डेटा) उपलब्ध असल्यास प्रभावी रणनीती बनवता येते. त्यामुळे साई डिजिटेक आपल्याला उत्तम दर्जाचे डेटा सहाय्य पुरवते. ज्यामध्ये सर्वेक्षण, विश्लेषण आणि अहवालाद्वारे माहिती पुरवली जाते.  त्यामुळे योग्य निर्णय घेण्यास आपल्याला मदत होते.        ",

        Home_card2:"सोशल मिडिया",
        Home_card2para:"सोशल मिडियाच्या प्रभावी वापरासाठी तज्ञ मंडळींची टीम तसेच वेगवेगळ्या भाषांमध्ये उत्तम दर्जाचे लेखन साहित्य आणि ग्राफिक्स सहाय्य.        ",

        Home_card3:"निवडणूक प्रचार समर्थन",
        Home_card3para:"घराबाहेरच्या कार्यक्रमांपासून ते घरातील कार्यक्रमांपर्यंत आम्ही तुम्हाला यशस्वी निवडणूक प्रचारासाठी आवश्यक असलेल्या सर्व साधनांनी सुसज्ज करतो. आमच्या Android आणि डेस्कटॉप सॉफ्टवेअरद्वारे तुम्ही सहजपणे मतदार आणि मतदारसंघाची माहिती मिळवू शकता.",

        Home_know_more:"निवडणूक प्रचार ",

        Home_beforefooter_para:"आपल्या विजयासाठी प्रत्येक मत महत्त्वाचे आहे!        ",
        Home_beforefooter_button:"आमच्याशी संपर्क साधा        ",

         // about page============================
         
        About_secind:"आमच्याबद्दल",
        About_subheading:"पॉलिटिकल मार्केटिंगमधील क्रांतिकारी बदलांसाठी सज्ज व्हा.        ",
        About_subdescription:"साई डिजिटेक हे आघाडीचे जनसंपर्क आणि डिजिटल मार्केटिंग फर्म असून राजकीय प्रचार व्यवस्थापन आणि सोशल मिडिया ब्रँडिंग ही आमची प्रमुख वैशिष्टे आहेत. ग्राहकांची गरज आणि उद्दिष्टे पूर्णपणे समजून घेण्याला आम्ही प्राधान्य देतो. त्यांच्या गरजेनुसार रणनीती तयार करण्यासाठी व्यापक संशोधन, सर्वेक्षण आणि विश्लेषणाचा वापर करून त्यानुसार रणनीती बनवतो. त्याद्वारे ग्राहकांची पार्श्वभूमी, प्रादेशिक गतीशैली, ताकद, कमकुवत आणि स्पर्धात्मक वातावरणाचे व्यापक मूल्यांकन करून प्रभावी रणनीतीचा वापर करून सोशल मिडीयाचा वापर आणि मार्केटिंग केली जाते. साई डिजिटेक येथे केवळ सहकार्य देऊन थांबत नाही, तर आम्ही आमच्या ग्राहकांच्या यशासाठी वचनबद्ध आहोत.     ",

        About_titles:"आमचीच निवड का करावी? ",
        About_heading:"जनतेच्या मनात तुमचे स्थान निर्माण कार्याचे प्रभावी माध्यम!        ",
        About_card1:"नाविन्यपूर्ण दृष्टिकोन",
        About_card1para:"आमची रणनीती ही आपली गरज ओळखून बनवली जाते. त्यामुळे ग्राहकांचे उद्दिष्ट समजल्यामुळे त्यानुसार एक प्रभावी रणनीती तयार होते.        ",

        About_card2:"संशोधनाभिमुख",
        About_card2para:"मतदार आणि मतदारसंघ समजून घेणे ही विजयाची गुरुकिल्ली आहे. संशोधन आणि विश्लेषणावर आमचा अधिक भर असल्यामुळे आम्ही धोरणात्मक नियोजनासाठी आवश्यक असलेले सर्वक्षण आणि डेटा आम्ही उपलब्ध करतो.",

        About_card3:"उत्कृष्ट सेवेसाठी वचनबद्ध",
        About_card3para:"तुमच्या यशाप्रती असलेली आमची बांधिलकी ही कायमच आम्हाला उत्कृष्ट सेवा, व्यापक दृष्टिकोन आणि संशोधनआधारित रणनीती तयार करण्यासाठी प्रेरित करते, ज्यामुळे तुमचा यशाचा मार्ग सुलभ होतो.        ",

        About_fact:'',
        About_fact_text:'सोशल मीडियाद्वारे प्रभाव पाडण्याची तुमची शक्ती ओळखा...',

        About_beforefooter_para:"सोशल मिडिया: तुमची जनमान्यता वाढविण्याचे प्रभावी माध्यम        ",
        About_beforefooter_button:"आमच्याशी संपर्क साधा      ",

          // services data support ==========================
          Data_support_secind:"डेटा सहाय्य  ",
          Data_support_subheading:"कारण ज्ञान हीच शक्ती आहे",
          Data_support_subdescription:"साई डिजिटेकचे डेटा सहाय्य विस्तृत सेवांसह निवडणूकीमध्ये आपले यश सुनिश्चित करते. मतदारांची माहिती व संपर्क सूक्ष्म नियोजनासह गोळा केले जाते, त्यानंतर त्यांचे SWOT आणि उमेदवारांच्या विशिष्ट मूल्यांकनांसह त्याचे विश्लेषण केले जाते. आमचे अहवाल उमेदवार निवडीविषयी आपल्याला त्यांत  सूचक अशी अंतर्दृष्टी प्रदान करतात. धोरणात्मक नियोजन, मिडिया वापरापासून ते जनसंपर्कापर्यंत सर्व क्षेत्रांचा आम्ही विचार करतो, ज्यामुळे सुज्ञ निर्णय घेणे आणि प्रभावी मोहिमा सुनिश्चित होतात.",
  
          Data_support_collection_secind:"माहिती गोळा करणे ",
          Data_support_collection_subheading:"आपल्या मतदारांना ओळख",
          Data_support_collection_subdescription:"निवडणूक मोहिमांसाठी आपल्या मतदारांबद्दल माहिती असणे खूप महत्त्वाचे आहे. साई डिजिटेक तुम्हाला तुमच्या मतदारांविषयी आवश्यक असलेला सर्व माहिती गोळा करून आणि खालील गोष्टींचा डेटाबेस तयार करून आपल्याला पुरवते:        ",
          Data_support_ul1_title1:'मतदार माहिती:',
          Data_support_ul1_li1:"गाव आणि शहरनिहाय",
          Data_support_ul1_li2:" कुटुंबनिहाय",
          Data_support_ul1_li3:"आडनावनिहाय",
          Data_support_ul1_li4:" बूथनिहाय",
          Data_support_ul1_li5:"प्रभागनिहाय",
          Data_support_ul1_li6:"पत्तानिहाय",
          Data_support_ul1_li7:"वयानुसार          ",
  
          Data_support_ul2_title2:'प्रभावशाली लोकांचे संपर्क तपशील:',
          Data_support_ul2_li1:"प्रशासकीय संस्था",
          Data_support_ul2_li2:" सहाय्यक समित्या",
          Data_support_ul2_li3:"सहकारी संस्था",
          Data_support_ul2_li4:"प्रभावशाली व्यक्ती",
          Data_support_ul2_li5:"इतर व्यावसायिक",
          Data_support_ul2_li6:"लहान व्यवसाय मालक",
          Data_support_ul2_li7:" राजकीय पक्षांचे नेते आणि पक्षांचे कार्यकर्ते ",
  
          Data_analysis_secind:"माहिती विश्लेषण",
          Data_analysis_subheading:"आकडे, धोरण आणि विजय          ",
          Data_analysis_subdescription:"         मोहिमेच्या धोरणांमधून जास्तीत जास्त आउटपुट मिळविण्यासाठी, माहितीचे विश्लेषण करणे आणि योग्य निष्कर्षांपर्यंत पोहोचणे महत्त्वाचे आहे. माहिती विश्लेषणामध्ये आमच्या खालील सेवांचा समावेश आहे:  ",
          Data_analysis_ul3_li1:"मागणीनुसार विश्लेषण    ",
          Data_analysis_ul3_li2:"SWOT विश्लेषण          ",
          Data_analysis_ul3_li3:"उमेदवारनिहाय विश्लेषण          ",
          Data_analysis_ul3_li4:"जातिनिहाय विश्लेषण          ",
          Data_analysis_ul3_li5:"पंचायत समितीनुसार धोरणात्मक विश्लेषण  ",
          Data_analysis_ul3_li6:"उमेदवारांसाठी शिफारसी       ",
  
         
          Data_candidate_survey_secind:"उमेदवार सर्वेक्षण अहवाल",
          Data_candidate_survey_subheading:"विजयासाठी निवड योग्यच हवी           ",
          Data_candidate_survey_subdescription:"साई डिजिटेकसोबत, आपल्या लोकचलित लोकशाहीत विजयाची खात्री करून सूचित उमेदवार निवडीसाठी तपशीलवार सर्वेक्षण अहवाल मिळवा.      ",
          Data_candidate_survey_ul4_li1:"धार्मिक/जातीय प्रभाव         ",
          Data_candidate_survey_ul4_li2:"लोकांच्या अपेक्षा",
          Data_candidate_survey_ul4_li3:"लोकांशी संपर्क आणि उपलब्धता",
          Data_candidate_survey_ul4_li4:"कार्यक्षमता आणि लोकप्रियता    ",
          Data_candidate_survey_ul4_li5:"उमेदवारांचे सामर्थ्य आणि विजयाची शक्यता ",
  
          Data_strategy_planning_secind:"धोरणात्मक नियोजन",
          Data_strategy_planning_subheading:"विजयासाठी नियोजन        ",
          Data_strategy_planning_subdescription:"साई डिजिटेक सर्वसमावेशक धोरणात्मक नियोजनातून विजयाची खात्री तुम्हाला देते. सूक्ष्म निरीक्षण आणि सखोल विश्लेषणासह सर्व क्षेत्रांचा विचार करून संपूर्ण धोरण व नियोजन केले जाते.",
          Data_strategy_planning_ul5_li1:"योग्य उमेदवार निवड      ",
          Data_strategy_planning_ul5_li2:"नियोजित प्रसिद्धी         ",
          Data_strategy_planning_ul5_li3:"प्रश्नरचना   ",
          Data_strategy_planning_ul5_li4:"मीडियाचा प्रभावी वापर ",
          Data_strategy_planning_ul5_li5:"जनसंपर्क     ",
          Data_strategy_planning_ul5_li6:"बुथ सक्षमीकरण    ",
          Data_strategy_planning_ul5_li7:"मतदान प्रक्रिया/प्रशिक्षण       ",
          Data_strategy_planning_ul5_li8:"नकारात्मक बुथ रणनीती ",

        //   social media support page===========

        Social_media_secind:"सोशल मिडिया",
        Social_media_support_subheading:"सोशल मीडिया का महत्त्वाचे आहे?        ",
        Social_media_support_subdescription:"सोशल मिडीयाच्या या युगात निवडणुका आता केवळ प्रचार आणि भाषणांपुरत्या मर्यादित राहिलेल्या नाहीत; तर त्या एक डिजिटल युद्ध बनल्या आहेत.  जिथे प्रत्येक क्लिक, लाइक आणि शेअर तुमच्या राजकीय भविष्याला आकार देतात. या युगात प्रत्येक ट्वीटला प्रचारातील घोषणांइतकेच वजन आहे आणि प्रत्येक हॅशटॅग मतदारांच्या मनाला भुरळ पाडण्याची ताकद बाळगते. अशा या सोशल मिडीयाच्या युगात आपले स्वागत आहे. सोशल मिडीयाने निवडणुकांचे स्वरूप कायमचे बदलून टाकले आहे आणि कोणताही उमेदवार अथवा पक्ष यापासून सुटलेला नाही.   ",
        Social_media_support_ul_title:"साई डिजीटेक खालील सेवा प्रदान करते:        ",
        Social_media_support_ul_li1:"खालील प्लॅटफॉर्मसाठी सोशल मीडिया सपोर्ट: फेसबुक, ट्विटर, इन्स्टाग्राम, व्हॉट्सॲप        ",
        Social_media_support_ul_li2:"सोशल मिडिया व्यवस्थापन  ",
        Social_media_support_ul_li3:"प्रोफाइल तयार करणे आणि व्यवस्थापन करणे        ",
        Social_media_support_ul_li4:"जास्तीत जास्त प्रभावासाठी धोरणात्मक लिखित आणि दृश्य सामग्री (ग्राफिक, व्हिडीओ) पुरवणे  ",
        Social_media_support_ul_li5:"फॉलोअर्स वाढवणे आणि ब्रँड इमेज वाढवणे        ",
        Social_media_support_ul_li6:" प्रेक्षकांशी वैयक्तिक संबंध प्रस्थापित करणे        ",
        Social_media_support_ul_li7:"लाईव्ह सेशन        ",
        Social_media_support_ul_li8:"सोशल मिडिया मार्केटिंग ",

        Social_media_effect:"सोशल मिडीयाचा प्रभाव        ",
        Social_media_heading:"माना अगर मानू नका, परंतु सोशल मिडीयाच्या युगात निवडणुकांचे स्वरूप पूर्णपणे बदलेले आहे.",
        Social_media_para:"गेल्या दशकभरात सोशल मिडियाने निवडणूक प्रचारात क्रांती घडवून आणली आहे. २०१२ मध्ये अमेरिकेचे माजी राष्ट्राध्यक्ष ओबामांच्या 'येस वी कॅन'ने जनतेला एकत्र केले, तर ट्रम्प यांच्या व्हायरल घोषणांनी २०१६ मध्ये विजय मिळवला. भारतात मोदींच्या 'अच्छे दिन आएंगे' या कार्यक्रमामुळे ऑनलाइन संवादाला उधाण आले आणि फडणवीस यांनी २०१४ मध्ये अचानक दृश्यमानतेसाठी सोशल मिडियाचा वापर केला.",

        Social_media_support_card1:"तरुण मनांपर्यंत पोहोचण्याचे माध्यम.  ",
        Social_media_support_card1para:"देशातील तरुण जनता तसेच निवडणुकीमध्ये पहिल्यांदाच मतदान करणाऱ्या लोकसंख्येपर्यंत पोहोचणे हे राजकारणातील विजयासाठी अत्यंत महत्त्वाचे आहे. या तरुण मतदारांपर्यंत पोहोचण्यासाठी सोशल मिडिया आपल्याला अत्यंत एक प्रभावी मार्ग प्रदान करते. ",
        Social_media_support_card2:"वैयक्तिक संबंध तयार करणे.",
        Social_media_support_card2para:"नेतृत्व म्हणजे लोकांना समजून घेणे आणि त्यांचा आवाज बनणे. सोशल मीडिया एक संबंधित आणि प्रवेश करण्यायोग्य प्रतिमा तयार करण्यास प्रोत्साहन देते. सार्वजनिक धारणा आणि सकारात्मकतेने आकार देते.        ",
        Social_media_support_card3:"मुख्य प्रवाहातील माध्यमांवर सोशल मीडियाचा प्रभाव आहे.        ",
        Social_media_support_card3para:"आज मुख्य प्रवाहातील मीडिया कव्हरेजसाठी सोशल मीडिया ट्रेंड महत्त्वपूर्ण प्रभाव पाडतात. या शक्तीचा उपयोग केल्याने उपस्थिती वाढते आणि आवाज मोठ्या प्रमाणात जनसमुदायापर्यंत पोहोचतो.       ",

          //  Election campaign support page=======
          Election_campaign_subheading:"विजयाचे परिपूर्ण पॅकेज          ",
          Election_campaign_subdescription:"आपली प्रचार मोहीम अधिक प्रभावी, अचूक आणि यशस्वी बनविण्यासाठी साई डिजीटेक काटेकोरपणे नियोजनाची हमी आपल्याला देते. यामध्ये हार्डवेअर आणि सॉफ्टवेअर सहाय्य, बूथ समर्थन, निवडणूक साधने आणि वस्तूंसह आमचे व्यापक सहकार्य आपली मोहीम अचूकता आणि कार्यक्षमतेने अंमलात आणली जाते याची खात्री करते. वेळोवेळी होणाऱ्या मार्गदर्शनामुळे आपल्या प्रचाराचा प्रत्येक पैलू संघटित आणि अनुकूलित केला जातो, ज्यामुळे निवडणुकीच्या आखाड्यात आपल्या यशाचा मार्ग मोकळा होतो.",
          Election_campaign_secind:"निवडणूक प्रचार यंत्रणा",
  
          Election_campaign_ul1_Secind:"सॉफ्टवेअर आणि हार्डवेअर सुविधा",
          Election_campaign_subheading1:"तंत्रज्ञानाचा योग्य वापर हीच यशाची किल्ली!",
          Election_campaign_subdescription1:"साई डिजीटेक कार्यक्षम निवडणूक प्रचारासाठी आवश्यक हार्डवेअर आणि सॉफ्टवेअर सपोर्ट देते. आमचे अँड्रॉइड आणि डेस्कटॉप सॉफ्टवेअर मतदार आणि मतदारसंघाच्या डेटावर त्वरित प्रवेश प्रदान करतात, मौल्यवान वेळेची बचत करतात. याव्यतिरिक्त आमच्या हार्डवेअर समर्थनामध्ये मतदान यंत्राच्या वापरावरील डेमो सत्रांचा समावेश आहे, यामुळे मतदारांना चांगली माहिती मिळते.          ",
          Election_campaign_secind1:"सॉफ्टवेअर आणि हार्डवेअर सुविधा",
          Election_campaign_ul1_li1:"अँड्रॉइड आणि डेस्कटॉप सॉफ्टवेअर",
          Election_campaign_ul1_li2:"मतदान यंत्र डेमो सत्रे          ",
  
          Election_campaign_subheading2:"बूथ म्हणजे विजयाचा पाया!          ",
          Election_campaign_subdescription2:"बूथ हे यशस्वी निवडणूक मोहिमेसाठी निर्णायक असतात, त्यामुळे बूथ विषयी रणनीती बनवणे अत्यंत गरजेचे आहे. साई डिजीटेक बूथचे तपशीलवार मूल्यांकन, सर्वेक्षण आणि विश्लेषणासह सर्वसमावेशक सहाय्य पुरवते. आम्ही बूथ सक्षमीकरणासाठी मदत करतो आणि बूथ प्रमुख आणि कार्यकर्त्यांना मार्गदर्शन आणि प्रशिक्षण देतो, प्रभावी व्यवस्थापन आणि मतदार सहभाग सुनिश्चित करतो.    ",
          Election_campaign_secind2:"बूथ समर्थन",
          Election_campaign_ul2_li1:"बूथ प्रमुखांसाठी मार्गदर्शन आणि प्रशिक्षण   ",
          Election_campaign_ul2_li2:"सर्वेक्षण, विश्लेषण आणि बूथची निर्मिती          ",
  
          Election_campaign_subheading3:"आता सर्व साधने आपल्या हातात!          ",
          Election_campaign_subdescription3:"साई डिजिटेक प्रभावी निवडणूक मोहिमांसाठी सर्वसमावेशक साधने प्रदान करते, ज्यामध्ये वॉर रूम आणि कॉल सेंटर्स सारखे इनडोअर पर्याय आणि एलईडी व्हॅन, ऑडिओ रिक्षा, सायकल जाहिराती आणि सांस्कृतिक कार्यक्रम यासारख्या बाह्य धोरणांचा समावेश आहे.         ",
          Election_campaign_secind3:"निवडणूक साधने",
          Election_campaign_ul3_li1:"वॉर रूम       ",
          Election_campaign_ul3_li2:"कॉल सेंटर       ",
          Election_campaign_ul3_li3:"व्हाईस कॉल     ",
          Election_campaign_ul3_li4:"शेड्युलिंग मोहीम    ",
          Election_campaign_ul3_li5:"एलईडी व्हॅन          ",
          Election_campaign_ul3_li6:"स्पीकरसह ऑडिओ रिक्षा          ",
          Election_campaign_ul3_li7:"सायकल जाहिराती          ",
          Election_campaign_ul3_li8:"स्ट्रीट शो       ",
          Election_campaign_ul3_li9:"लोक कला (उदा. भारुड, वासुदेव, इ.)     ",
  
          Election_campaign_subheading4:"प्रिंट, इलेक्ट्रॉनिक आणि मर्चेंडाईज सपोर्ट     ",
          Election_campaign_subdescription4:" ",
          Election_campaign_secind4:"",
          Election_campaign_ul4_li1:"War Room        ",
          Election_campaign_ul4_li2:"Call Centre        ",
          Election_campaign_ul4_li3:"Voice Call        ",
          Election_campaign_ul4_li4:"Scheduling Campaign        ",
          Election_campaign_ul4_li5:"LED VAN        ",
          Election_campaign_ul4_li6:"Audio Rickshaw with Speakers",
          Election_campaign_ul4_li7:"	Bicycle Advertisements",
          Election_campaign_ul4_li8:"Street Shows        ",
          Election_campaign_ul4_li9:"Loka Kala (e.g., Bharud, Vasudev, etc.)        ",

        // footer =============================
        Footer_quick:"क्विक लिंक ",
        Footer_home:"मुखपृष्ठ         ",
        Footer_about:"आमच्याबद्दल ",
        Footer_gallery:"गैलरी        ",
        Footer_contact:"संपर्क ",
        Footer_services:"आमच्या सेवा",
        Footer_data:"माहिती सहकार्य  ",
        Footer_social_media:"सोशल मिडिया सहकार्य  ",
        Footer_election_campaign:"निवडणूक प्रचारमोहीम  ",
        Footer_Legal:"कायदेशीर",
        Footer_privacy:"गोपनीयता धोरण         ",
        Footer_cookies:"कुकीज धोरण         ",
        Footer_address:"ऑफिस / कार्यालय नं. २१४-२१६, १० बिझ पार्क, सिम्बॉयसिस लॉ कॉलेज जवळ, एअरपोर्ट रोड, विमान नगर, पुणे- ४११०१४",

        // header=============
       
        Header_home:"मुखपृष्ठ         ",
        Header_about:"आमच्याबद्दल  ",
        Header_gallery:"गैलरी  ",
        Header_contact:"संपर्क ",
        Header_services:"आमच्या सेवा",
        Header_data:"माहिती सहकार्य  ",
        Header_social_media:"सोशल मिडिया सहकार्य  ",
        Header_election_campaign:"निवडणूक प्रचारमोहीम  ",

        // gallery page==================================

        Gallery_election:"निवडणूक पोस्ट ",
        Gallery_marketing:"मार्केटिंग पोस्ट",
        Gallery_calender:"जयंती, पुण्यतिथी विशेष पोस्ट",

         // contact page====================================

         Contact_heading:"चला एक नवीन सुरुवात करूया!",
         Contact_para:"कृपया खालील फॉर्म भरा. तुमचा संदेश साई डिजीटेक टीमकडून अत्यंत गोपनीयतेने हाताळला जाईल.",
         Contact_first_name:"नाव",
         Contact_last_name:"आडनाव",
         Contact_phone:"फ़ोन ",
         Contact_email:"ईमेल ",
         Conatct_msg:"आपला संदेश इथे टाईप करा",
         Contact_send:"संदेश पाठवा",
         Contact_add:"पता ",
         Contact_para_add:"ऑफिस / कार्यालय नं. २१४-२१६, १० बिझ पार्क, सिम्बॉयसिस लॉ कॉलेज जवळ, एअरपोर्ट रोड, विमान नगर, पुणे- ४११०१४",

         // page not found========================================
        page_not_button:"मुख्यपृष्ठावर परत"
         
         
         
 

  







    },
];
export default marathi;